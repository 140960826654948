import { lazy, useEffect, Suspense, FC } from "react";
import { Toaster } from "react-hot-toast";
import { AuthProvider, useAuth } from "./contexts/auth-context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const loadAuthenticatedApp = () => import("./router/auth");
const AuthenticatedApp = lazy(loadAuthenticatedApp);
const UnauthenticatedApp = lazy(() => import("./router/unauth"));

const queryClient = new QueryClient();
function Routers() {
  const { isAuthenticated } = useAuth();

  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  return <div className="router h-screen">{isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}</div>;
}
const App: FC = () => {
  return (
    <Suspense fallback={<div />}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Routers />
          <Toaster reverseOrder position="top-left" />
        </AuthProvider>
      </QueryClientProvider>
    </Suspense>
  );
};

export default App;
