import { createContext, ReactNode, useContext, FC, useState, useEffect } from "react";
import { storage_keys } from "../api/constants";
import { LoginResponse } from "../api/interface/auth.interface";
import { CustomEventEmitter, EKey } from "./event-context";

type Context = { isAuthenticated: boolean; login?(data: LoginResponse): void; accountData: LoginResponse["data"] | undefined; logout?(): void };
export const Context = createContext<Context>({ isAuthenticated: false, accountData: undefined });

type P = { children: ReactNode };
export const AuthProvider: FC<P> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accountData, setAccountData] = useState<LoginResponse["data"]>();

  useEffect(() => {
    authCheck();
  }, []);

  useEffect(() => {
    const unbind = CustomEventEmitter.instance.on("call", (p: EKey) => {
      if (p === "logout") {
        setIsAuthenticated(false);
      }
    });

    return unbind;
  }, []);
  function login(data: LoginResponse) {
    localStorage.setItem(storage_keys["jwt-token"], data.data.token ?? "");
    localStorage.setItem(storage_keys["user"], JSON.stringify(data.data));
    setIsAuthenticated(true);
    setAccountData(data["data"]);
  }

  function logout() {
    localStorage.clear();
    setIsAuthenticated(false);
  }

  function authCheck() {
    const data = localStorage.getItem(storage_keys["user"]);
    if (data) {
      setAccountData(JSON.parse(data));
      setIsAuthenticated(true);
    }
    return undefined;
  }
  return <Context.Provider value={{ isAuthenticated, accountData, login, logout }}>{children}</Context.Provider>;
};

export const useAuth = () => {
  try {
    const c = useContext(Context);
    return c;
  } catch (error) {
    throw new Error(`useAuth must be called from within an AuthProvider`);
  }
};
