import { createNanoEvents, Emitter } from "nanoevents";

export type EKey = "items" | "mappings" | "saving" | "saving-successful" | "saving-failed" | "logout";
interface Events {
  call(key: EKey, data?: any): void;
}
export class CustomEventEmitter {
  static instance = new CustomEventEmitter();
  emitter: Emitter<Events>;
  constructor() {
    this.emitter = createNanoEvents<Events>();
  }

  on(event: keyof Events, callback: (...params: any) => void) {
    return this.emitter.on(event, callback);
  }

  call(key: EKey, data?: any) {
    this.emitter.emit("call", key, data);
  }
}
